import React, { FunctionComponent } from 'react';

import { pageIndex } from 'components/lexemes';
import { CdnImagePath } from 'lib/cdn-image-path';

import PageSectionLandingSuccessStories from 'components/page/sections/page-section-landing-success-stories';

export const LandingBlockFourContainer: FunctionComponent = () => {
    const props = {
        title: pageIndex.lxPageIndexFourBlockTitle,
        items: [
            {
                imageSrc: `${CdnImagePath}/landing/assets/photos/landing-carousel-1.jpg`,
                imageAlt: '',
                title: pageIndex.lxPageIndexFourBlockOneItemTitle,
                text: pageIndex.lxPageIndexFourBlockOneItemText,
                href: '/the-buzz/bumble-for-friends-success-story-hannah-ashlyn',
            },
            {
                imageSrc: `${CdnImagePath}/landing/assets/photos/landing-carousel-3.jpg`,
                imageAlt: '',
                title: pageIndex.lxPageIndexFourBlockTwoItemTitle,
                text: pageIndex.lxPageIndexFourBlockTwoItemText,
                href: '/the-buzz/bumble-success-story-brian-kaci',
            },
            {
                imageSrc: `${CdnImagePath}/landing/assets/photos/landing-carousel-2.jpg`,
                imageAlt: '',
                title: pageIndex.lxPageIndexFourBlockThreeItemTitle,
                text: pageIndex.lxPageIndexFourBlockThreeItemText,
                href: '/the-buzz/bumble-success-story-carene-you-liang',
            },
        ],
    };

    return <PageSectionLandingSuccessStories title={props.title} cards={props.items} />;
};
