import React, { FunctionComponent } from 'react';

import { getImageData } from 'lib/get-image-data';
import Image from 'lib/image-container';
import { SupportedLanguage } from 'lib/lexemes/supported-languages';
import { MediaQueryWidth } from 'lib/use-media-query';
import useScrollbarWidth from 'lib/use-scrollbar-width';

import Button from 'components/button/button';
import { Columns, ColumnsItem } from 'components/columns';
import { CtaBox, CtaBoxActions, CtaBoxText, CtaBoxHeader } from 'components/cta-box';
import PageSection, { PageSectionProps } from 'components/page/page-section';

const PAGE_PADDING_SMALL = '16px';
const PAGE_PADDING_MEDIUM = '64px';
const PAGE_PADDING_LARGE = '108px';
const COLUMNS_GAP_MEDIUM = '32px';
const IMAGE_MAX_WIDTH_1 = '561px';

type Props = {
    action: { onClick?: () => void; href?: string; text: string };
    title?: string | React.ReactNode;
    titleElement?: string | React.ReactNode;
    titleLang?: SupportedLanguage;
    text: string | React.ReactNode;
    image: {
        src: string;
        objectPosition: string;
        // position in desktop layout, 'end' by default
        inlinePosition?: 'start' | 'end';
        hasLoadPriority?: boolean;
    };
    color: PageSectionProps['color'];
    bulletPoints?: Array<string>;
};

const PageSectionCta: FunctionComponent<Props> = ({
    image,
    action,
    title,
    titleElement,
    titleLang,
    text,
    color,
    bulletPoints,
}) => {
    const scrollbarWidth = useScrollbarWidth(true);
    const blurDataUrl = getImageData(image.src).blurDataUrl;

    return (
        <PageSection color={color} extraClass="section-cta" padding="block" inner={true}>
            <Columns
                equal={true}
                hasMobileLayout={true}
                isMobileOrderInverted={image.inlinePosition !== 'start'}
            >
                <ColumnsItem verticalAlign="stretch">
                    <div
                        className="page-section__picture"
                        style={
                            blurDataUrl
                                ? {
                                      backgroundImage: `url("${blurDataUrl}")`,
                                      backgroundSize: 'cover',
                                      backgroundPosition: image.objectPosition,
                                  }
                                : undefined
                        }
                    >
                        <Image
                            priority={image.hasLoadPriority}
                            width={600}
                            height={620}
                            style={{ objectFit: 'cover', objectPosition: image.objectPosition }}
                            quality={75}
                            src={image.src}
                            alt=""
                            placeholder={blurDataUrl ? 'blur' : undefined}
                            blurDataURL={blurDataUrl}
                            sizes={`
                                (max-width: ${MediaQueryWidth['desktop-sm']}px) calc(100vw - 2 * ${PAGE_PADDING_SMALL} - ${scrollbarWidth}),
                                (max-width: ${MediaQueryWidth['tablet-lg']}px) calc((100vw - 2 * ${PAGE_PADDING_MEDIUM} - ${COLUMNS_GAP_MEDIUM} - ${scrollbarWidth}) / 2),
                                (max-width: ${MediaQueryWidth['content-max']}px) calc((100vw - 2 * ${PAGE_PADDING_LARGE} - ${COLUMNS_GAP_MEDIUM} - ${scrollbarWidth}) / 2),
                                ${IMAGE_MAX_WIDTH_1}
                            `}
                        />
                    </div>
                </ColumnsItem>

                <ColumnsItem>
                    <CtaBox align="start">
                        {titleElement ? (
                            <CtaBoxHeader lang={titleLang}>{titleElement}</CtaBoxHeader>
                        ) : (
                            <CtaBoxHeader tag="h2" lang={titleLang}>
                                {title}
                            </CtaBoxHeader>
                        )}

                        <CtaBoxText>
                            {text}
                            {bulletPoints &&
                                bulletPoints.map((point, index) => (
                                    <div key={index}>
                                        <p className="cta-box__bullet-point">{point}</p>
                                    </div>
                                ))}
                        </CtaBoxText>

                        <CtaBoxActions align="start">
                            <Button
                                href={action.href}
                                text={action.text}
                                onClick={action.onClick}
                            />
                        </CtaBoxActions>
                    </CtaBox>
                </ColumnsItem>
            </Columns>
        </PageSection>
    );
};

export default PageSectionCta;
